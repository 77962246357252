@import url('https://fonts.googleapis.com/css?family=Nunito');

body {
	font: 15px "Nunito", Segoe UI;
	margin: 0;
} 

a:link, a:visited {
    color: #2ab1ee;
    transition: all 0.4s;
    text-decoration: none;
}
a:hover, a:focus {
    color: #79dfff;
}

.header-image-vignette {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    width: 33%;
    height: 100%;
    position: absolute;
}

.header-image-vignette-right {    
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    width: 33%;
    height: 100%;
    position: absolute;
    right: 0;
}

.header-image-title-vignette {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    width: 100%;
    height: 160px;
    position: absolute;
    bottom: 0;
}

.header-image-title-wrapper {
    max-width: 1600px;
    justify-content: space-between;
    text-align: center;
    margin: 15px 0;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    display: flex;
}

.header-image-title-flex-wrapper {    
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}


.header-image-title {    
    font-size: 75px;
    font-weight: lighter;
    color: white;
    transition: opacity 1.2s, transform 1.2s;
}

.header-image-subtitle {
    color: white;
    font-size: 24px;
    transition: opacity 0.8s, transform 0.8s;
    margin-bottom: 10px;
}

div#page-footer-wrapper {
    width: 100%;
    background: #111;
    box-shadow: 0 0 5px -1px #333;
    color: #fff;
}

div#page-footer {
    max-width: 1550px;
    margin: auto;
    padding: 15px 5px;
}

.react-tabs {
	text-align: center;
}

.project-overview {
	max-width: 1550px;
}

.header-image {    
    height: 65vh;
    min-height: 350px;
    max-height: none;
    background-image: url(/public/images/headshot.jpg);
    background-position: 50% 60%;
    background-attachment: scroll;
    background-position: 50% 40%;
    background-size: cover;
    transition: background 3s;
    position: relative;
}

div#view-wrapper {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
	background-color: #333;
    color: #fff;
}

.intro-text {
    text-align: center;
    max-width: 1200px;
    width: 100%;
    font-size: 20px;
    padding: 30px 0;
}

.overview-text {
    font-size: 18px;
    padding: 10px 0;
}

.cv-list, .overview-text, .project-overview {
    max-width: 1500px;
	width: 100%;
}

.react-tabs {
	width: 100%;
}

.white-section {
    background: #fff;
    width: 100%;
    color: #222;
    display: flex;
    justify-content: center;
}

/**
 * Styles for Tab-like components:

 * - React Tabs
 * - View options
 * - Toggle components
*/
.react-tabs__tab-list {
    border-bottom: 1px solid #555;   
    padding-bottom: 0;
    padding-left: 10px;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 0;
}

#main-tabs .react-tabs__tab-list {
    border-bottom: 1px solid #ddd;
}

.react-tabs__tab-list > li {
    padding: 10px 15px;
    transition: color .4s;
    cursor: pointer;
    margin-right: 6px;
    border-bottom: 0px;
    align-items: center;
    display: flex;
    border: 1px solid transparent;
    font-size: 25px;
}

.tabs-with-links .react-tabs__tab-list > li {
    padding: 0;
}

.tabs-with-links .react-tabs__tab-list > li a, .tabs-with-links .react-tabs__tab-list > li span {
    padding: 10px 15px;
}

li.save-button {
    flex: 1;
    justify-content: flex-end;
}

#main-tabs .react-tabs__tab {
    padding: 10px 15px;
}

.react-tabs .react-tabs__tab a {
    color: inherit;
    cursor: inherit;
    transition: none;
}

/** First tab depth **/
.react-tabs .react-tabs__tab--selected, .react-tabs__tab--selected {
    font-weight: bold;
    cursor: auto;
    margin-bottom: -1px;
    border-bottom: 2px solid #169fce;
}

.react-tabs .react-tabs__tab--selected,
.react-tabs__tab-list > li:hover {
    color: #169fce;
}

section.dark-bg {
    background: #222;
    width: 100%;
    color: #eee;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-down {
	flex-direction: column;
}

section.cv-list, .project-overview {
    font-size: 18px;
    text-align: left;
}

.cv-list h1 {
    display: flex;
    justify-content: space-between;
    color: #ff8549;
}

span.location {
    font-style: italic;
    font-weight: 100;
	min-width: 130px;
	text-align: right;
}

.cv-list > ul {
    list-style-type: "-  ";
}

p.skills-list {
    font-weight: bold;
}

.cv-list h3 {
    font-style: italic;
	font-weight: 100;
}

.cv-list li {
    margin: 8px 0;
}

.stat-tracker-player-data ~ .overview-highlight-row {
	margin-top: 25px;
}

.overview-highlight-row {
    box-shadow: 0 0 3px 3px #ff8549;
    padding: 10px;
	margin: 35px 0;
}

.overview-highlight-row .info {
    margin: 0px 0 10px 10px;
}

.overview-highlight-row h4 {
    color: #ff8549;
    border-bottom: 2px solid #ff8549;
    display: inline-block;
    margin-bottom: 0;
}

.overview-highlight-row.second {
	box-shadow: 0 0 3px 3px #009e00;
}

.overview-highlight-row.second h4 {
	color: #009e00;
    border-bottom: 2px solid #009e00;
}

.overview-highlight-row.third {
	box-shadow: 0 0 3px 3px #bc73ff;
}

.overview-highlight-row.third h4 {
	color: #bc73ff;
    border-bottom: 2px solid #bc73ff;
}

.progress-line-chart .canvas-wrapper {
    height: 400px;
    width: unset;
    float: none;
}

.progress-panel.panel {
    padding: 15px 15px 0;
}

.panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.4s;
    border: 1px solid transparent;
    flex: 1;
}

.panel p {
    font-size: 18px;
    margin: 0;
}

.panel-buttons button {
    color: #2ab1ee;    
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    font: 15px "Nunito", Segoe UI;
    cursor: pointer;
}

.react-tabs.tabs-horizontal {
    display: flex;
    justify-content: space-around;
}

.react-tabs.tabs-horizontal .react-tabs__tab-panel {
    /* flex: 0; */
}

.react-tabs.tabs-horizontal > *,
.react-tabs.tabs-horizontal .react-tabs__tab-panel--selected {
    /* flex: 1; */
}


.tabs-horizontal .react-tabs__tab-list {
    flex-direction: column;
    border-bottom: 0;
}

.tabs-horizontal .react-tabs__tab-list > * {
    border-bottom: 1px solid #eee;
}

.profile-stats-pergame {
    display: block;
    font-size: 14px;
}

span.value-pos-row span {
    margin-left: 4px;
}

span.position-top100 {
    color: #26d226;
    font-size: 14px;
}

span.profile-stats {
    font-size: 14px;
    width: 18%;
}
span.profile-stats.zero-value {
    opacity: 0.4;
}

section.project-overview {
    border-bottom: 3px solid #666;
}

section.project-overview.no-border,
.white-section section.project-overview {
	border-bottom: none;
}

h1.big-header {
    font-size: 32px;
    border-bottom: 1px solid;
    margin-top: 25px;
}

p.header {
    font-size: 18px;
}

.intro-text.low-pad {
	padding: 0;
	padding-bottom: 15px;
    margin-top: -15px;
}

h2 {
    font-size: 1.4em;
}

.flex.flex-wrap {
    flex-wrap: wrap;
}

@media (min-width: 2000px) {
	.project-overview {
        max-width: 1950px;
		width: unset;
	}
	span.player-comparison-stats span.weapon-halo5 {
		height: 130px;
	}
}

@media (max-width: 1500px) {
	
	#view-wrapper .intro-text {
		font-size:  16px;
		padding: 10px 15px;
	}

	#page-footer-wrapper, .overview-text, section.cv-list, .project-overview {
		padding: 0 15px;
		font-size: 15px;
	}

	.react-tabs__tab-list > li {
		font-size: 18px;
	}
	
	#page-footer-wrapper  #page-footer {
		padding: 10px;
	}

	.cv-list h1 {
		font-size: 1.2em;
	}
	.cv-list h3 {
		font-size: 1em;
	}
}

@media (max-width: 1000px) {
	.header-image {
		height: 40vh;
	}
	.header-image-vignette {
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.34), rgba(0, 0, 0, 0));
	}
	.header-image-vignette-right {
		background: linear-gradient(270deg, rgba(0, 0, 0, 0.34), rgba(0, 0, 0, 0));
	}
	.header-image-title {
		font-size: 50px;
	}
	.header-image-title-wrapper {
		margin: 10px 0;
	}
	.header-image-subtitle {
		margin-bottom: 0;
	}
	.header-image-title-vignette {
		height: 130px;
	}
	#view-wrapper .intro-text.low-pad {
		padding-bottom: 15px;
	}
}